// 顶部导航各项目信息
import { projectAll } from "@/api/system/user.js";

const state = {
  menus: [],
};
const mutations = {
  SETTING_MENUS(state, data) {
    state.menus = data;
  },
};
const actions = {
  // 获取顶部导航各项目信息
  GetProjectAll({ state, commit }) {
    if (state.menus && state.menus.length > 0) {
      return Promise.resolve(state.menus);
    }
    return new Promise((resolve, reject) => {
      projectAll().then((res) => {
        commit("SETTING_MENUS", Array.isArray(res) ? res : []);
        resolve(res);
      });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
