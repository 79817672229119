import Vue from "vue";
import Clipboard from "clipboard";

function clipboardSuccess(message) {
  Vue.prototype.$message({
    message: message || "Copy successfully",
    type: "success",
    duration: 1500,
  });
}

function clipboardError(message) {
  Vue.prototype.$message({
    message: message || "Copy failed",
    type: "error",
  });
}

export default function handleClipboard(text, event, msg, errMsg) {
  const clipboard = new Clipboard(event.target, {
    text: () => text,
  });
  clipboard.on("success", () => {
    clipboardSuccess(msg);
    clipboard.off("error");
    clipboard.off("success");
    clipboard.destroy();
  });
  clipboard.on("error", () => {
    clipboardError(errMsg);
    clipboard.off("error");
    clipboard.off("success");
    clipboard.destroy();
  });
  clipboard.onClick(event);
}
