// 基础信息管理
import request from "@/utils/request";

const baseUrl = "/aws";
// 基础信息管理 base-business
export function baseBusiness(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-base-business/list",
    method: "get",
    params: data,
  });
}

// 供应商管理-编辑
export function editSupplier(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-base-business/editSupplier",
    method: "post",
    data: data,
  });
}
// 新增与编辑 新街口
export function saveOrUpdateSupplier(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-base-business/saveOrUpdateSupplier",
    method: "post",
    data: data,
  });
}

// SKU管理-编辑
export function editMaterial(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-base-business/editMaterial",
    method: "post",
    data: data,
  });
}

//  采购人员管理-对应飞书人员
export function FeishuEditBuyer(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-base-business/editBuyer",
    method: "post",
    data: data,
  });
}

// 收款账户
export function accountList(id) {
  return request({
    url: baseUrl + "/pc/tb-jindie-supplier/list/" + id,
    method: "put",
  });
}

// 收款账户保存
export function accountSave(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-supplier/save",
    method: "post",
    data: data,
  });
}

// 收款账户删除
export function accountRemove(id) {
  return request({
    url: baseUrl + "/pc/tb-jindie-supplier/delete/" + id,
    method: "delete",
  });
}

// 项目列表
export function getAwsProject(data) {
  return request({
    url: baseUrl + "/pc/tb-aws-project/list",
    method: "post",
    data: data,
  });
}

// 新增/编辑项目
export function updateAwsProject(data) {
  return request({
    url: baseUrl + "/pc/tb-aws-project/saveOrUpdate",
    method: "post",
    data: data,
  });
}

// 删除项目
export function delAwsProject(id) {
  return request({
    url: baseUrl + "/pc/tb-aws-project/delete/" + id,
    method: "delete",
  });
}

// 消息提醒
export function deliveryRemindersFn() {
  return request({
    url: baseUrl + "/pc/tb-jindie-shipment-progress-child/deliveryReminders",
    method: "get",
  });
}

// 确认消息
export function messageConfirmationFn() {
  return request({
    url: baseUrl + "/pc/tb-jindie-shipment-progress-child/messageConfirmation",
    method: "get",
  });
}

// 撤销审批 - sku页面
export function revoke(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-base-business/revoke",
    method: "get",
    params: data,
  });
}