const VUE_APP_VERSION = require("../package.json").version;
//对比版本号是否一致 更新缓存并强制刷新
if (VUE_APP_VERSION != localStorage.getItem("AppVersion")) {
  window.localStorage.setItem("AppVersion", VUE_APP_VERSION);
  window.location.reload(true);
}

import "./public-path";
import Vue from "vue";

import Cookies from "js-cookie";

import "normalize.css/normalize.css";

// import Element from "element-ui";
//
// import mavonEditor from 'mavon-editor'
// import 'mavon-editor/dist/css/index.css'

// 数据字典
import dict from "./components/Dict";

// 权限指令
import permission from "./components/Permission";
import "./assets/styles/element-variables.module.scss";
// global css
import "./assets/styles/index.scss";

// 代码高亮
import VueHighlightJS from "vue-highlightjs";
import "highlight.js/styles/atom-one-dark.css";

import App from "./App";
import store from "./store";
import router from "./router/routers";

import "./assets/icons"; // icon
import "./router/index"; // permission control

import DecryptPhone from "./components/DecryptPhone"; // 展示手机号并提供解密获取真实手机号

import ElSTable from "./components/ElSTable";
import FilterColumn from "./components/ElSTable/Filter";
import ElSImage from "./components/ElSImage";
import ExportButton from "./components/ExportButton";
import { loadScript } from "@/utils/index.js";
loadScript(
  "https://imgcache.qq.com/open/qcloud/video/tcplayer/libs/hls.min.0.12.4.js"
);
loadScript(
  "https://imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.v4.min.js"
);
loadScript("https://cdn-go.cn/cdn/vod-js-sdk-v6/latest/vod-js-sdk-v6.js");
Vue.prototype.$ELEMENT = { size: Cookies.get("size") || "small" };

Vue.prototype.openPage = function (name, par) {
  this.$router.push({ name: name, query: par });
};
Vue.prototype.goBack = function () {
  this.$router.back();
};

Vue.use(VueHighlightJS);
// Vue.use(mavonEditor);
Vue.use(permission);
Vue.use(dict);
// Vue.use(Element, {
//   size: Cookies.get("size") || "small", // set element-ui default size
// });
Vue.use(ElSTable);
Vue.use(FilterColumn);
Vue.use(ElSImage);
Vue.use(ExportButton);
Vue.use(DecryptPhone);

Vue.prototype.$alertMsgBox = function alert() {
  return this.$prompt("您确定要删除此数据吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    inputPlaceholder: "请输入DELETE，进行删除",
    inputPattern: /^[D][E][L][E][T][E]+$/,
    inputErrorMessage: "请输入DELETE，进行删除",
  }).then(() => {});
};
// Vue.prototype.$closeSelectedTag = (view) => {
//   // 关闭标签页
//   store.dispatch("tagsView/delView", view).then(() => {
//     router.back();
//   });
// };
Vue.prototype.$closeSelectedTag = (view, customJump) => {
  // 关闭标签页 customJump: 是否跳转指定页面
  return store.dispatch("tagsView/delView", view).then(() => {
    // 关闭后不自定义跳转 则 默认返回 上一个页面，自定义跳转请在调用页面 then中执行
    if (!customJump) {
      router.back(-1);
    }
  });
};

Vue.prototype.removeInvalidKey = function (obj) {
  // 移除对象中值为 '' null undefined 的字段
  const resultObj = {};
  for (let i in obj) {
    if (obj[i] || obj[i] === 0 || obj[i] === false) {
      resultObj[i] = obj[i];
    }
  }
  return resultObj;
};

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
// let router = null;
// let instance = null;
// function render(props) {
//   const { container } = props;
//   router = routers;
//   instance = new Vue({
//     router,
//     store,
//     provide: container ? container.mainAppRouter : {}, // 初始化时直接挂载到实例
//     render: (h) => h(App),
//   }).$mount(container ? container.querySelector("#app") : "#app"); // 这里是挂载到自己的html中  基座会拿到这个挂载后的html 将其插入进去
// }

// // if (window.__POWERED_BY_QIANKUN__) { // 动态添加publicPath
// //   __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
// // }
// if (!window.__POWERED_BY_QIANKUN__) {
//   // 默认独立运行
//   render({});
// }

// // 父应用加载子应用，子应用必须暴露三个接口：bootstrap、mount、unmount
// // 子组件的协议就ok了
// export async function bootstrap(props) {
//   console.log("bootstrap-SCRM", props);
//   store.state.publiced.windowState = true;
// }

// export async function mount(props) {
//   console.log("mount-SCRM", props);
//   render(props);
// }

// export async function unmount() {
//   instance.$destroy();
//   instance.$el.innerHTML = "";
//   instance = null;
//   router = null;
//   store.state.windowState = false;
// }

// export async function update(props) {
//   console.log("update-SCRM", props);
// }

// el-select选择，动态加载更多。
var timer = "";
Vue.directive("loadmore", {
  inserted(el, binding) {
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector(
      ".el-select-dropdown .el-select-dropdown__wrap"
    );
    if (SELECTWRAP_DOM) {
      SELECTWRAP_DOM.addEventListener("scroll", function () {
        // + 10 : 防止浏览器有时候不触底
        const CONDITION =
          this.scrollHeight - this.scrollTop <= this.clientHeight + 10;
        if (CONDITION) {
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            binding.value();
          }, 100);
        }
      });
    }
  },
});
