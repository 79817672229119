import request from "@/utils/request";

const baseUrl = "/aws";
// 采购需求管理列表
export function tbJindiePurchaseOrderList(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/list",
    method: "post",
    data: data,
  });
}

// 发起审批
export function initiateApproval(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/initiateApproval",
    method: "post",
    data: data,
  });
}

// 拆分订单
export function splitOrder(data, id) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/splitOrder/" + id,
    method: "put",
    data: data,
  });
}

// 审批信息
export function approvalInfo(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/approvalInfo",
    method: "get",
    params: data,
  });
}

// 金额，供应商变动提醒
export function changeReminders(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/changeReminders",
    method: "post",
    data: data,
  });
}

// 获取总数
export function getCount(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/count",
    method: "post",
    data: data,
  });
}

// 作废
export function disuse(id, cause) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/disuse/" + id,
    method: "get",
    params: { cause: cause },
  });
}

// 暂缓下单
export function suspendOrder(id, cause) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/suspendOrder/" + id,
    method: "get",
    params: { cause: cause },
  });
}

// 采购订单发起批量审批验证
export function approvalValid(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/approvalValid",
    method: "post",
    data: data,
  });
}

// 发起批量审批
export function initiateApprovalBatch(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/initiateApprovalBatch",
    method: "post",
    data: data,
  });
}

// 撤销
export function withdrawOrder(id) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/withdraw/" + id,
    method: "delete",
  });
}

// 仅保存
export function save(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/save",
    method: "post",
    data: data,
  });
}

// 获取sku历史单价
export function getSkuPrice(skuCode) {
  return request({
    url: baseUrl + "/pc/tb-aws-cost/getSkuPrice/" + skuCode,
    method: "put",
  });
}

// 生成子订单
export function generateChildOrders(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/generateChildOrders",
    method: "post",
    data: data,
  });
}

// 订单预计交货时间消息提醒--查看详情
export function reminder_detailsFn() {
  return request({
    url: baseUrl + "/pc/tb-jindie-shipment-progress-child/reminder_details",
    method: "get",
  });
}

// 获取历史单价 供应商
export function getSupplierFn(skuCode) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/getSupplier/" + skuCode,
    method: "put",
  });
}

// 设置特别关注
export function updateFollowTheState(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/updateFollowTheState",
    method: "post",
    data: data,
  });
}

// 取消特别关注
export function cancelFollowTheState(data) {
  return request({
    url: baseUrl + "/pc/tb-jindie-purchase-order/cancelFollowTheState",
    method: "post",
    data: data,
  });
}
