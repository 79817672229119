import request from '@/utils/request'

export function delMaterialInfo(data) {
  return request({
    url: '/common-server/pc/material/delMaterialInfo',
    method: 'get',
    params: data
  })
}

export function editMaterialInfo(data) {
  return request({
    url: '/common-server/pc/material/editMaterialInfo',
    method: 'post',
    data: data
  })
}

export function getMaterialList(data) {
  return request({
    url: '/common-server/pc/material/getMaterialList',
    method: 'get',
    params: data
  })
}

export function getMaterialTypeList(data) {
  return request({
    url: '/common-server/pc/material/getMaterialTypeList',
    method: 'get',
    params: data
  })
}

export function getvideoPsign(data) {
  return request({
    url: '/common-server/tencent/getvideoPsign',
    method: 'get',
    params: data
  })
}

export function addMaterialType(data) {
  return request({
    url: '/common-server/pc/material/addMaterialType',
    method: 'post',
    data: data
  })
}

export function upLoadFile(data) {
  return request({
    url: '/common-server/pc/material/upLoadFile',
    method: 'post',
    data: data
  })
}

export function uploadVideo(data) {
  return request({
    url: '/common-server/pc/material/uploadVideo',
    method: 'post',
    data: data
  })
}

export function addMaterialInfo(data) {
  return request({
    url: '/common-server/pc/material/addMaterialInfo',
    method: 'post',
    data: data
  })
}
//查询字典表
export function queryDicDetailType(data) {
  return request({
    url: '/common-server/pc/dict-detail/map',
    params:data,
    method: 'get',
  })
}
//下载中心列表
export function getDownList(data) {
  return request({
    url: '/common-server/pc/down/getDownList',
    method: 'get',
    params: data
  })
}
//下载中心获取所有数据来源
export function getAllDataSource(data) {
  return request({
    url: '/common-server/pc/down/getAllDataSource',
    method: 'get',
    params: data
  })
}
//下载中心操作-下载
export function setDown(data) {
  return request({
    url: '/common-server/pc/down/down',
    method: 'post',
    data: data
  })
}
//下载中心-统一下载 spring方式
export function download(data,sign) {
  return request({
    url: '/common-server/pc/down/spring',
    method: 'post',
    data: {requestParams: JSON.stringify(data),sign:sign}
  })
}
//导入中心列表
export function getImportantList(data) {
  return request({
    url: '/common-server/pc/import/getList',
    method: 'get',
    params: data
  })
}
