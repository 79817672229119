import request from "@/utils/request";

/**
 * 获取短链
 * @param {*} data
 * longUrl 原链接
 * isUpdate 1 更新短链 默认不传
 * @returns
 */
export function getSortLink(data) {
  return request({
    url: "/common-server/pc/sortServer/getSortLink",
    method: "post",
    data,
  });
}

// 解密手机号
export function getUnprotectPhone(data) {
  return request({
    url: "/common-server/pc/authority/getUnprotectPhone",
    method: "post",
    data,
  });
}
