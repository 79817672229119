<!-- 图片展示组件 处理图片长短地址 支持字符串 数组，多个图片点击查看 -->
<template>
  <div
    class="img_view"
    :style="{ width: width + 'px', height: (height || width) + 'px' }"
  >
    <template v-if="src && filterImgFirst(src)">
      <el-image
        :src="filterImgFirst(src)"
        :preview-src-list="filterImg(src)"
        :z-index="3063"
        fit="contain"
      >
      </el-image>
    </template>
    <template v-else>
      <el-image
        slot="error"
        fit="contain"
        src="https://hxlimage.hongxinglin.com/1/20240724/ic_goods_default.png"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: "ElSImage",
  props: {
    src: {
      type: String | Array,
    },
    width: {
      type: Number,
      default: 60,
    },
    height: {
      type: Number,
    },
  },
  data() {
    return {
      publicUrl: this.$store.state.public.publicImgUrl,
    };
  },
  methods: {
    filterImgFirst(imgPaths, publicUrl = this.publicUrl) {
      const imgList = this.filterImg(imgPaths, publicUrl);
      return imgList[0];
    },
    filterImg(imgPaths, publicUrl = this.publicUrl) {
      imgPaths = imgPaths
        ? Array.isArray(imgPaths)
          ? imgPaths
          : imgPaths.split(",")
        : [];
      imgPaths = imgPaths.map((item) => {
        if (item.indexOf("http") < 0) {
          item = `${publicUrl}${item}`;
        }
        return item;
      });
      return imgPaths.length > 0 ? imgPaths : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.img_view {
  position: relative;
  margin: 0 auto;
  .el-image {
    display: block;
    height: 100%;
  }
}
</style>
