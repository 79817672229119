// 导出 并跳转下载中心
<template>
  <el-button :type="type" @click="downLoadCenter({ sign, params, tips })">
    <slot />
  </el-button>
</template>

<script>
import { download as downloadCenter } from "@/api/content/material.js";
export default {
  name: "ExportButton",
  props: {
    type: {
      type: String,
    },
    sign: {
      type: Object,
    },
    params: {
      type: Object,
    },
    tips: {
      type: String,
    },
  },
  methods: {
    downLoadCenter({ sign, params, tips }) {
      this.$confirm(tips || "你确定要导出该数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //跳入下载中心
          downloadCenter(params, sign).then((res) => {
            if (res.code == 200) {
              this.$router.push({
                path: "/downloadcenter/index",
              });
            } else {
              this.$notify({
                message: res.message,
                type: "error",
              });
            }
          });
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>
