// import request from '@/utils/requestBak'
import request from '@/utils/request'

export function uploadVideo(data) { // 获取视频播放参数
  return request({
    url: '/common-server/tencent/getvideoPsign',
    method: 'get',
    params: data
  })
}


export function getPublicImgUrl(data) {  //  获取图片公共 域名
  return request({
      url: '/common-server/common/getSystemConfigInfo',
      method: 'get',
      params: data
  })
}

export function uploadVideoUrl() { // 视频上传地址
  return 'http://121.199.5.67:8208/common-server/tencent/uploadVoid'
}

export function uploadImgUrl() { // 图片上传地址
  return 'http://121.199.5.67:8208/common-server/aliFile/upLoadFileNoSecret'
}
