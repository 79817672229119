<template>
  <div>
    <el-dialog
      title="温馨提示"
      :visible.sync="$store.state.supplyChainsManage.msgTipsShow"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @opened="openDia"
    >
      <span>{{ $store.state.supplyChainsManage.msgContent }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="yesIKnow">知道了</el-button>
        <el-button type="primary" @click="lookDetail">查看详情</el-button>
      </span>
    </el-dialog>
    <tipsDetailVue ref="tipsDetailVue"></tipsDetailVue>
  </div>
</template>
<script>
import tipsDetailVue from "./tipsDetail.vue";
import { messageConfirmationFn } from "@/api/supplyChainsManage/basicInformation.js";
export default {
  name: "msgTips",
  data() {
    return {
      dialogVisible: true,
      num: 0,
    };
  },
  components: {
    tipsDetailVue,
  },
  mounted() {},
  methods: {
    open() {
      this.$store.state.supplyChainsManage.msgTipsShow = true;
    },
    lookDetail() {
      this.$refs.tipsDetailVue.open();
    },
    handleClose() {
      this.$store.state.supplyChainsManage.msgTipsShow = false;
    },
    openDia() {
      console.log("wocao");
    },
    yesIKnow() {
      messageConfirmationFn().then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.$store.state.supplyChainsManage.msgTipsShow = false;
          return;
        }
        this.$message.error(res.message);
      });
    },
  },
};
</script>
