<template>
  <div>
    <el-dialog
      title="消息提醒-详情"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-s-table
        style="width: 100%"
        border
        :data="tableData"
        :columns="columns"
      >
      </el-s-table>
      <!-- <div class="pagebox">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNumber"
          :page-sizes="[10, 20, 30, 40, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
        ></el-pagination>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { reminder_detailsFn } from "@/api/supplyChainsManage/purchaseOrder.js";
export default {
  name: "tipsDetail",
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      pageNumber: 1,
      pageSize: 10,
      columns: [
        {
          prop: "purchaseBatches",
          label: "采购批次",
          "min-width": 220,
          fit: true,
        },
        { prop: "purchaseNo", label: "金蝶单据编号", "min-width": 120 },
        { prop: "receiptNo", label: "收料单编号", "min-width": 120 },
        { prop: "skuCode", label: "SKU编码" },
        { prop: "skuName", label: "SKU名称", "min-width": 250 },
        { prop: "project", label: "归属项目", "min-width": 200 },
        { prop: "purchaserName", label: "采购负责人" },
        { prop: "qty", label: "订单数量" },
        { prop: "statusStr", label: "子发货状态" },
        { prop: "estimateTime", label: "预估发货时间", "min-width": 160 },
        { prop: "estimateQty", label: "预估发货数量", "min-width": 160 },
        {
          prop: "realityTime",
          label: "实际发货时间",
          "min-width": 160,
          sortable: true,
        },
        { prop: "realityQty", label: "实际发货数量" },
        {
          prop: "logisticsNo",
          label: "物流单号",
          "min-width": 160,
          sortable: true,
        },
        { prop: "freight", label: "运费" },
        { prop: "arrivalTime", label: "到货时间", "min-width": 160 },
        { prop: "warehouseName", label: "入库仓库", "min-width": 120 },
        { prop: "inboundQty", label: "入库数量" },
        {
          prop: "inboundTime",
          label: "入库时间",
          "min-width": 160,
          sortable: true,
        },
        {
          prop: "supplierName",
          label: "供应商",
          "min-width": 250,
          sortable: true,
        },
        {
          prop: "createTime",
          label: "创建时间",
          "min-width": 160,
          sortable: true,
        },
        { prop: "createUser", label: "创建人" },
      ],
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.getList();
    },
    lookDetail() {},
    handleClose() {
      this.dialogVisible = false;
    },
    // handleSizeChange(val) {
    //   this.pageSize = val;
    //   this.pageNumber = 1;
    //   this.getList();
    // },
    // handleCurrentChange(val) {
    //   this.pageNumber = val;
    //   this.getList();
    // },
    getList() {
      reminder_detailsFn().then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          return;
        }
        this.$message.error(res.message);
      });
    },
  },
};
</script>
