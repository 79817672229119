import { getPublicImgUrl } from '@/api/upload/upload'

const state = {
  publicImgUrl: '',
  collectionModeList: {
    1: "微信",
    2: "支付宝",
    3: "邮政银行",
    4: "建设银行",
    5: "腾讯课程",
    6: "小鹅通",
    7: "有赞平台",
    8: "民生银行",
    9: "POST机",
    10: "现金",
    11: "学习平台",
    12: "工商银行溁湾镇支行",
    13: "平安银行",
    14: "中信银行",
    15: "湖南红杏林中医门诊有限公司",
    16: "长沙市岳麓区红杏林职业技能培训学校",

    19: "培训学校收款二维码",
    20: "红杏林二维码收款",
    21: "湖南红杏林文化传播有限公司收款码",
    22: "中国民生银行长沙湘江新区支行",
    23: "刘华生",
    24: "医考通收款码",
    25: "红杏林科技收款码",
    26: "红杏林文化一般户收款码",
  }
}
const mutations = {
  SETTING_PUBLIC_IMGURL(state, data) {
    state.publicImgUrl = data
  },
}
const actions = {
  // 获取图片公共 域名
  SetPublicImgUrl({ commit }) {
    return new Promise((resolve, reject) => {
      getPublicImgUrl().then(res => {
        if (res.code == 200) {
          commit('SETTING_PUBLIC_IMGURL', res.data.filePath)
        } else {
          // console.log(res.message)
        }
      })
    })
  }
}

export default {
  namespaced: false,
  state,
  mutations,
  actions,
}